import React from 'react'
import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Button, Form, Input, Select, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "../../../features/Auth/AuthSlice.jsx";
import { toast } from "react-toastify";
import axios from "axios";
import { baseApiUrl } from "../../../Utils/constants.js";
import { updateWalletBalance, walletBalance } from "../../../features/Wallet/WalletSlice.jsx";
import Spinner from "../../../Component/Spinner.jsx";
import ReactGA from 'react-ga';

const Buy_Airtime = () => {
  const { user } = useSelector(getUser)
  const { balance } = useSelector(walletBalance)
  const [network, setNetwork] = useState('')
  const [calculatedAmount, setCalculatedAmount] = useState()
  const [commission, setCommission] = useState()
  const [airtime, setAirtime] = useState()
  const [Loading, setLoading] = useState(false)
  const [disable, setDisable] = useState(false)
  const errorTokenExpired = 'token expired'
  const errorTokenInvalid = "Token is invalid"
  const airtimeCommisionApi = 'api/airtime-commission/get-commission-by-network'
  const buyAirtimeApi = 'api/airtime/buy-airtime'

  const [form] = Form.useForm();

  const dispatch = useDispatch()

  const networkOnChange = (value) => {
    setNetwork(value)
  }


  const BuySoniteAirtime = async (data) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Clicked on Buy Now',
      label: 'Buy Airtime',
    });
    const headers = {
      'Authorization': `Bearer ${user?.accessToken}`,
      'Content-Type': 'application/json'
    }

    try {
      const response = await axios.post(`${baseApiUrl}/${buyAirtimeApi}`, data, { headers })
      const { success, message, walletBalance } = response.data
      if (success === true) {
        toast.success(message)
        dispatch(updateWalletBalance(walletBalance))
      } else {
        toast.error(message)
      }
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    } finally {
      form.resetFields()
      setLoading(false)
      setDisable(false)
    }
  }


////Buy TranzitAirtime
const BuyTranzitAirtime = async (data) => {
  ReactGA.event({
    category: 'Button Click',
    action: 'Clicked on Buy Now',
    label: 'Buy Airtime',
  });
  const headers = {
    'Authorization': `Bearer ${user?.accessToken}`,
    'Content-Type': 'application/json'
  }

  try {
    const res = await axios.post(`${baseApiUrl}/${buyAirtimeApi}`, data, { headers })
    const { status,  response } = res.data
    if (status === "Successful") {
      toast.success(response)
      dispatch(updateWalletBalance(walletBalance))
    } else {
      toast.error(response)
    }
  } catch (error) {
    if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (error.response.data) {
      toast.error(error.response.data.message)
    } else {
      toast.error(error)
    }
  } finally {
    form.resetFields()
    setLoading(false)
    setDisable(false)
  }
}

////Buy BuyRingoAirtime
const BuyRingoAirtime = async (data) => {
  ReactGA.event({
    category: 'Button Click',
    action: 'Clicked on Buy Now',
    label: 'Buy Airtime',
  });
  const headers = {
    'Authorization': `Bearer ${user?.accessToken}`,
    'Content-Type': 'application/json'
  }

  try {
    const res = await axios.post(`${baseApiUrl}/${buyAirtimeApi}`, data, { headers })
    const { message,  status } = res.data
    if (message === "Successful" && status===200) {
      toast.success(message)
      dispatch(updateWalletBalance(walletBalance))
    } else {
      toast.error(message)
    }
  } catch (error) {
    if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
      toast.error('Error!! Please Login again')
      dispatch(logout())
    } else if (error.response.data) {
      toast.error(error.response.data.message)
    } else {
      toast.error(error)
    }
  } finally {
    form.resetFields()
    setLoading(false)
    setDisable(false)
  }
}





///submission for airtime
  const onFinish = async (e) => {
    setDisable(true)
    setLoading(true)
    if (!calculatedAmount) {
      toast.info('No amount!! Please input airtime amount')
      form.resetFields()
      setLoading(false)
      setDisable(false)
    } else if (calculatedAmount > balance) {
      toast.error('Insufficient Funds in Wallet')
      form.resetFields()
      setLoading(false)
      setDisable(false)
    } else if (e.msisdn[0] === '0' && e.msisdn.length === 11) {
      const FormData = { Network: e.productCode, msisdn: e.msisdn, amount: calculatedAmount, airtime }
      BuyRingoAirtime(FormData)
    } else if (e.msisdn.length !== 11) {
      toast.info('Mobile Number is Incomplete')
      form.resetFields()
      setLoading(false)
      setDisable(false)
    } else {
      toast.info('Mobile Number Format is incorrect')
      form.resetFields()
      setLoading(false)
      setDisable(false)
    }
  }
  const fetchCommissionForUser = async () => {
    try {
      setLoading(true)
      let networkparamsindex = network.indexOf("VTU")
      let networkparams = network.substring(0, networkparamsindex).toLowerCase()
      const headers = {
        'Authorization': `Bearer ${user?.accessToken}`,
        'Content-Type': 'application/json'
      }
      const response = await axios.get(`${baseApiUrl}/${airtimeCommisionApi}/${networkparams}`, { headers })
      setCommission(response.data.commission)
    } catch (error) {
      if (error.response.data === errorTokenExpired || error.response.data === errorTokenInvalid) {
        toast.error('Error!! Please Login again')
        dispatch(logout())
      } else if (error.response.data) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (network) {
     fetchCommissionForUser()
      setAirtime()
      form.resetFields(["airtime"])
      form.resetFields(["msisdn"])
      setCalculatedAmount()
    }
  }, [network])

  useEffect(() => {
    let amountToDeduct = commission / 100 * airtime
    setCalculatedAmount(airtime - amountToDeduct)
  }, [commission, airtime])


  return (
    <div>
      <Helmet defer={false}>
        <title>Fliprecharge || Buy Airtime</title>
      </Helmet>
      {Loading && <Spinner />}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" style={{ height: "70vh" }}></div>
      {/* Page content */}
      <Container className="" style={{ marginTop: "-22rem" }} fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Buy Airtime</h3>
              </CardHeader>
              <CardBody>
                <Form form={form} className='w-100 bg-white p-4 rounded-xl' onFinish={onFinish}>
                  <label htmlFor="">Network</label>
                  <Form.Item className="w-100" name="productCode" rules={[{ required: 'true', message: "Please select a network" }]}>
                    <Select value={network} onChange={networkOnChange}>
                    <Select.Option value='MTNVTU'>MTN</Select.Option>
                    <Select.Option value='9MOBILEVTU'>9Mobile</Select.Option>
                    <Select.Option value='GLOVTU'>Glo</Select.Option>
                    <Select.Option value='AIRTELVTU'>Airtel</Select.Option>
                    </Select>
                  </Form.Item>
                  <label htmlFor="">Mobile Number</label>
                  <Form.Item className="w-100" name="msisdn" rules={[{ required: 'true', message: "Please input your Mobile Number" }]}>
                    <Input maxLength={11}
                      suffix={
                        <Tooltip title="Eg: 0812345678">
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <label htmlFor="">Airtime</label>
                  <Form.Item className="w-100" name="airtime" rules={[{ required: 'true', message: "Please input airtime amount" }]}>
                    <Input type='number' value={airtime} onChange={(e) => setAirtime((Number(e.target.value)))} placeholder='Airtime Amount' />
                  </Form.Item>
                  <label htmlFor="">Amount</label>
                  <Form.Item className="w-100" rules={[{ required: 'true', message: "Please input amount" }]}>
                    <Input value={calculatedAmount} disabled type='number' />
                  </Form.Item>
                  <Form.Item>
                    <Button className="bg-gradient-info text-white d-flex justify-content-center align-items-center my-3 px-4 py-3 rounded-lg" htmlType="submit" disabled={disable}>Buy now</Button>
                  </Form.Item>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Buy_Airtime